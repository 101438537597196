import { assign, pick } from 'lodash';

// TODO: Currently have to manually keep 'color' attributes here in sync
// with styles in _app-source-init.scss, which is clunky...
export const EVENT_TYPES = {
  GENERAL: {
    key: 'GENERAL',
    title: 'General',
    color: '#2557D1',
    description: '',
  },
  MEDICAL: {
    key: 'MEDICAL',
    title: 'Medical Care',
    color: '#2E6C74',
    description: '',
  },
  INCIDENT: {
    key: 'INCIDENT',
    title: 'Incident',
    color: '#48651A',
    description: '',
  },
  DEPOSITION: {
    key: 'DEPOSITION',
    title: 'Deposition',
    color: '#7F5019',
    description: '',
  },
  FILING: {
    key: 'FILING',
    title: 'Filing',
    color: '#B4317D',
    description: '',
  },
  DEATH: {
    key: 'DEATH',
    title: 'Death',
    color: '#BE2E2D',
    description: '',
  },
};

// TODO: Evan: I ABSOLUTELY HATE HARDCODING A PROMPT INTO THE MODEL, but it seems to be the pattern we're setting elsewhere
// TO BE DISCUSSED
export const AI_PROMPT = `Convert the contents of the section marked "Source Legal Chronology" below into a series of structured Events and return it as a JSON array.

Each Event object in the array should have the following properties populated:

"date" - the date on which the event took place, in ISO format. If date is not specified for a given Event, assume it to be the same date as the prior Event, if available
"type" - the type of Event, according to the Event Type taxonomy defined below
"summary" - a summary in layman's terms of what occurred on that date in 12 words or less
"source" - the ID of the Paragraph from which the Event was parsed

Event Types:

The value for the "type" property of each Event must be in all caps and must exactly match one of the following:

INCIDENT: the original incident that led to legal proceedings, e.g., an accident or instance of purported malpractice
FILING: the official legal filing of a claim
DEPOSITION: official recording of a deposition pursuant to legal proceedings around an incident
MEDICAL: medical treatment pertaining to a victim referenced in the chronology
DEATH: the death of a person referenced in the chronology
GENERAL: a catch-all bucket for other Events that do not clearly fit into any of the five other Event Types

Note that if multiple MEDICAL Events occurred on the same day in regards to the same patient,
they should be combined into a single Event which summarizes the medical proceedings, and the max length of that summary can be increased to 25 words.
It is not necessary to granularly list every medical step that occurred as its own discrete Event.
However, if those medical events are fatal, i.e., if they culminated in death, we DO still need an additional DEATH Event to be included.

Source Legal Chronology:
`;

// TODO: This is for keeping track of whether a timeline event is still in its original
// AI-generated form, or if it has been human-edited, as per Jeremy's design. The name "status"
// is a WIP and probably not perfect, but seems OK for now.
export const TimelineEventStatus = Object.freeze({
  ai: 'AI',
  human: 'HUMAN',
});

export default class TimelineEvent {
  id = null;
  status = TimelineEventStatus.ai;
  date = null;
  type = null;
  summary = null;
  source = null;

  constructor(json) {
    assign(this, pick(json, ['id', 'status', 'date', 'type', 'summary', 'source']));
  }

  get json() {
    return pick(this, ['id', 'status', 'date', 'type', 'summary', 'source']);
  }
}
